import React from 'react';
import GlobalStyle from './globalStyles';
import {
  InfoSection,
  ContactSection,
  AboutUsSection,
  LightboxPage,
  Angebot,
  Agb,
  Datensh,
  Impressum,
  Navbar,
  Footer,
} from './components';
import Error from './components/ErrorPage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

function MainPage(props) {
  return (
    <div className='App'>
      <InfoSection />
      <Angebot />
      <AboutUsSection />
      <ContactSection />
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path='/' exact component={MainPage} />
        <Route path='/galerie' exact component={LightboxPage} />
        <Route path='/agb' exact component={Agb} />
        <Route path='/datenschutzerklarung' exact component={Datensh} />
        <Route path='/impressum' exact component={Impressum} />
        <Route path='*'>
          <Error />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}
