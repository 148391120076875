import React from "react";

import { Container } from "../../globalStyles";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapperReferenzen,
  TextWrapper,
  Heading,
  Subtitle,
  AnchorTags,
  AnchorTagsDiv,
  ImgWrapper,
  Img,
} from "../InfoSection/InfoSection.elements";
import img from "../../images/aboutUs-svg.webp";

function AboutUsSection() {
  return (
    <div id='überuns'>
      <InfoSec>
        <Container>
          <InfoRow imgStart>
            <InfoColumn>
              <TextWrapper>
                <Heading>Über uns</Heading>
                <Subtitle>
                  <p>
                    Wir sind ein junges, zuverlässiges und vertrauenswürdiges
                    Reinigungsteam.
                  </p>
                  <br />
                  <p>
                    Milas-SauberZauber ist Ihr Ansprechspartner für Reinigungen
                    in der Region Bern, Bern-Mittelland und Emmental.
                  </p>
                  <br />
                  <p>
                    Zufriedene Kunden sind unser oberstes Ziel, welches wir
                    durch unsere Qualität, herrvorragendes
                    Preis-Leistungs-Verhältnis und korrektes Verhalten gegenüber
                    Kunden erreichen.
                  </p>
                </Subtitle>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper>
                <Img src={img} alt='img' />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
          <TextWrapperReferenzen>
            <Heading>Referenzen</Heading>

            <AnchorTagsDiv>
              <ul>
                <li>
                  <AnchorTags href='https://apotheke-ryser.ch/' target='_blank'>
                    Apotheke Ryser AG
                  </AnchorTags>
                </li>
                <li>
                  <AnchorTags
                    href='https://www.bimo-personal.ch/kontakt/burgdorf/'
                    target='_blank'
                  >
                    Bimo-personal AG Burgdorf
                  </AnchorTags>
                </li>
                <li>
                  <AnchorTags href='https://creafloor.ch/' target='_blank'>
                    Creafloor GmbH Bodenbeläge & Innenausbau
                  </AnchorTags>
                </li>
                <li>
                  <AnchorTags href='https://www.diacosa.ch/' target='_blank'>
                    Diacosa AG
                  </AnchorTags>
                </li>
                <li>
                  <AnchorTags href='https://www.hofmann.swiss/' target='_blank'>
                    Garage Hofmann AG
                  </AnchorTags>
                </li>
                <li>
                  <AnchorTags
                    href='https://gschwend-immobilien.ch/'
                    target='_blank'
                  >
                    Gschwend Immobilien GmbH
                  </AnchorTags>
                </li>
                <li>
                  <AnchorTags
                    href='https://www.immobi.ch/index.html'
                    target='_blank'
                  >
                    Immobi Liegenschaften AG
                  </AnchorTags>
                </li>
              </ul>
            </AnchorTagsDiv>
          </TextWrapperReferenzen>
        </Container>
      </InfoSec>
    </div>
  );
}

export default AboutUsSection;
