import React from 'react';
import emailjs from 'emailjs-com';
import { Container, Button } from '../../globalStyles';
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  ImgWrapper,
  InfoColumnDiv,
  Img,
  Heading,
} from '../InfoSection/InfoSection.elements';
import img from '../../images/19197471.webp';
import Swal from 'sweetalert2';

function ContactSection() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_kapl0wf',
        'template_0u66h1i',
        e.target,
        'user_h2ZPcW9HyDUiWzaRXOwx5'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    Swal.fire(
      'Danke für Ihre Nachricht!',
      'Wir werden uns in kürze mit Ihnen in Verbindung setzen.',
      'success'
    );
    e.target.reset();
  }

  return (
    <InfoColumnDiv id='kontakt'>
      <InfoSec lightBg>
        <Container>
          <InfoRow>
            <InfoColumn>
              <TextWrapper>
                <Heading lightText>Kontaktieren Sie uns</Heading>
                <form onSubmit={sendEmail}>
                  <input type='name' name='name' placeholder='Name' required />
                  <input
                    type='email'
                    name='email'
                    placeholder='E-Mail'
                    required
                  />
                  <input
                    type='phone'
                    name='phone'
                    placeholder='Telefon'
                    required
                  />
                  <textarea
                    rows='4'
                    name='comment'
                    placeholder='Ihre Nachricht'
                    required
                  />

                  <Button
                    big
                    fontBig
                    variant='outline-light'
                    id='action-btn'
                    type='submit'
                  >
                    Einreichen
                  </Button>
                </form>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start>
                <Img src={img} alt='img' />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </InfoColumnDiv>
  );
}

export default ContactSection;
