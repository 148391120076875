export default [
  {
    id: '2',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791742/img2_wn54qz.webp',
  },

  {
    id: '10',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791743/img10_sphif6.webp',
  },
  {
    id: '3',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791740/img3_iewh0y.webp',
  },
  {
    id: '4',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791745/img4_uqigij.webp',
  },
  {
    id: '1',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636284950/img1_mmxe7i.webp',
  },
  {
    id: '5',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791739/img5_l5dabh.webp',
  },
  {
    id: '6',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791748/img6_nuucpv.webp',
  },
  {
    id: '7',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791748/img7_qjsnq0.webp',
  },
  {
    id: '8',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791742/img8_epnvo1.webp',
  },
  {
    id: '9',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791742/img9_yzvuca.webp',
  },

  {
    id: '11',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791744/img11_weiy81.webp',
  },
  {
    id: '12',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791746/img12_zxhukz.webp',
  },
  {
    id: '13',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791746/img13_bj9hoh.webp',
  },
  {
    id: '14',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791746/img14_psnqs4.webp',
  },

  {
    id: '16',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791747/img16_lhagqx.webp',
  },
  {
    id: '17',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791749/img17_k79bba.webp',
  },
  {
    id: '18',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791748/img18_c5lrk5.webp',
  },
  {
    id: '19',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791748/img19_wbzu7s.webp',
  },
  {
    id: '24',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791751/img24_d4blhm.webp',
  },

  {
    id: '23',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791752/img23_mimfqv.webp',
  },
  {
    id: '20',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791750/img20_vbpz36.webp',
  },
  {
    id: '21',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791753/img21_bhjxra.webp',
  },
  {
    id: '22',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791750/img22_ibztiz.webp',
  },

  {
    id: '25',
    imageName:
      'https://res.cloudinary.com/dxzdiqvpy/image/upload/v1636791752/img25_b25ddl.webp',
  },
];
