import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from '../../globalStyles';
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
} from '../InfoSection/InfoSection.elements';
import img from '../../images/AboutUs.webp';

function Angebot() {
  return (
    <div id='angebot'>
      <InfoSec>
        <Container>
          <InfoRow>
            <InfoColumn>
              <TextWrapper>
                <Heading>
                  Milas-SauberZauber bietet Firmen und privaten Stellen ihre
                  Dienstleistungen an:
                </Heading>
                <Subtitle>
                  <ul>
                    <li>Büroreinigung</li>
                    <li>Praxisreinigung</li>
                    <li>Firmenreinigung</li>
                    <li>Grundreinigung</li>
                    <li>Unterhaltsreinigung</li>
                    <li>Fensterreinigung</li>
                    <li>Storenreinigung</li>
                    <li>Umzugsreinigung</li>
                  </ul>
                </Subtitle>
                <Link to='/galerie'>
                  <Button big fontBig>
                    Galerie
                  </Button>
                </Link>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start>
                <Img src={img} alt='img' />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </div>
  );
}

export default Angebot;
