import React from 'react';
import './Lightbox.css';
import images from './data';
import { SRLWrapper } from 'simple-react-lightbox';

function LightboxPage() {
  return (
    <div>
      <SRLWrapper>
        <div className='container'>
          {images.map((image) => (
            <div key={image.id} className='image-card'>
              <a href={image.imageName}>
                <img className='image' src={image.imageName} alt='' />
              </a>
            </div>
          ))}
        </div>
      </SRLWrapper>
    </div>
  );
}

export default LightboxPage;
