import React from 'react';

import { Container } from '../../globalStyles';
import {
  InfoSec,
  TextWrapperTwo,
  HeadingTwo,
  SubtitleTwo,
} from '../InfoSection/InfoSection.elements';

function Agb() {
  return (
    <>
      <InfoSec>
        <Container>
          <TextWrapperTwo>
            <HeadingTwo>Allgemeine Geschäftsbedingungen</HeadingTwo>
            <SubtitleTwo>
              <p>
                Diese AGB regeln die Beziehung zwischen den Kundinnen und Kunden
                und der Milas-SauberZauber und gelten für deren
                Dienstleistungen. Die Milas-SauberZauber behält sich
                ausdrücklich vor, die AGB jederzeit zu ändern. Anderslautende
                vertragliche Vereinbarungen gehen den allgemeinen Bestimmungen
                vor.
              </p>
              <br />
              <p>
                Die Erstellung des Reinigungsplanes und die Organisation der
                Arbeitsabläufe werden durch Milas-SauberZauber ausgeführt.
                Milas-SauberZauber verpflichtet sich, die ihr übertragenen
                Arbeiten fachgerecht durchzuführen und das dafür erforderliche
                Personal einzusetzen. Allfällig mangelhaft ausgeführte Arbeiten
                sind der Milas-SauberZauber bis spätestens am folgenden
                Arbeitstag zu melden. Bei begründeter Beanstandung kann der
                Kunde eine Nachreinigung verlangen. Ressourcen wie Wasser sowie
                Strom werden Milas-SauberZauber unentgeltlich zur Verfügung
                gestellt. Nach Möglichkeit stellt der Auftraggeber
                Milas-SauberZauber ein Abstell- oder Putzraum unentgeltlich für
                die Lagerung der Putzutensilien zur Verfügung.
              </p>
              <br />
              <p>
                Unsere Offerten haben eine Gültigkeit von 30 Tage ab
                Ausstellungsdatum.
              </p>
              <br />
              <p>
                Die vom Kunden zu bezahlenden Preise für Dienstleistungen
                ergeben sich aus dem Auftrag. Die gesetzliche Mehrwertsteuer ist
                in den Preisen nicht enthalten. Allfällige Erhöhungen werden dem
                Kunden ohne separate Anzeige verrechnet.
              </p>
              <br />
              <p>
                Schlüssel werden nur gegen Abgabe einer Schlüsselquittung in
                Empfang genommen. Nach Beendigung des Arbeitsverhältnisses
                werden die Schlüssel gegen Aushändigung einer Schlüsselquittung
                zurückgegeben.
              </p>
              <br />
              <p>
                Die Betriebshaftpflichtversicherung der Milas-SauberZauber deckt
                Personen und Sachschäden von CHF 5'000'000 ab. Schäden aller Art
                müssen innert 24 Stunden bzw. bei der Abnahme des
                Reinigungsauftrags bei der Milas-SauberZauber gemeldet werden.
              </p>
              <br />
              <p>
                Für alle nicht ausdrücklich geregelten Punkten gelten die
                Bestimmungen des Schweizerischen Obligationenrechts. Schweizer
                Recht ist für alle Streitigkeiten anwendbar. Der Gerichtsstand
                ist der Ort der Unternehmerin.
              </p>
            </SubtitleTwo>
          </TextWrapperTwo>
        </Container>
      </InfoSec>
    </>
  );
}

export default Agb;
