import React from 'react';
import { Container, Button } from '../globalStyles';
import {
  TextWrapperError,
  HeadingError,
} from './InfoSection/InfoSection.elements';

import { Link } from 'react-router-dom';
const ErrorPage = () => {
  return (
    <>
      <Container>
        <TextWrapperError>
          <HeadingError>404</HeadingError>
          <HeadingError>
            Entschuldigung, die von Ihnen aufgerufene Seite kann nicht gefunden
            werden
          </HeadingError>
          <Link to='/'>
            <Button big fontBig>
              Zurück Home
            </Button>
          </Link>
        </TextWrapperError>
      </Container>
    </>
  );
};

export default ErrorPage;
